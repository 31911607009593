import Vue from 'vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import App from './App.vue'
import VueRouter from "vue-router";
import {router} from './router'

Vue.use(VueRouter)
Vue.use(Vant)

Vue.prototype.isInMini = function () {
  console.log('called isInMini')
  return window.__wxjs_environment === 'miniprogram'
}
Vue.prototype.navMini = function (to) {
  // eslint-disable-next-line no-undef
  wx.miniProgram.navigateTo({url: `/pages${to}${to}`})
}
Vue.prototype.navTo = function(path) {
  if (this.isInMini()) {
    // eslint-disable-next-line no-undef
    wx.miniProgram.navigateTo({url: `/pages${path}${path}`})
  } else {
    this.$router.push(path)
  }
}
Vue.prototype.backTo = function(path) {
  if (this.isInMini()) {
    // eslint-disable-next-line no-undef
    wx.miniProgram.navigateBack({url: `/pages${path}${path}`})
  } else {
    this.$router.replace(path)
  }
}

Vue.filter('zeroFill', value => {
  if (typeof value === 'number') {
    if (value < 10) return `00${value}`
    if (value < 100) return `0${value}`
  }
  return value
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
