import VueRouter from "vue-router";

const router = new VueRouter({
    routes: [
        {path: '/', component: () => import('@/pages/Index')},
        {path: '/index', component: () => import('@/pages/Index')},
        {path: '/login', component: () => import('@/pages/Login'), meta: {title: '登录'}},
        {path: '/locker', component: () => import('@/pages/Locker'), meta: {title: '选柜'}},
        {path: '/pay', component: () => import('@/pages/Pay'), meta: {title: '支付'}},
        {path: '/paySuccess', component: () => import('@/pages/paySuccess'), meta: {title: '支付成功'}},
        {path: '/area', component: () => import('@/pages/AreaList'), meta: {title: '选择区域'}},
        {path: '/consume', component: () => import('@/pages/Consume'), meta: {title: '消费明细'}},
        {path: '/register', component: () => import('@/pages/Register'), meta: {title: '注册'}},
        {path: '/reset', component: () => import('@/pages/ResetPassword'), meta: {title: '忘记密码'}},
        // {path: '/newComment', name: 'newComment', component: () => import('@/pages/NewComment')},
        // {path: '/userInfo', component: () => import('@/pages/UserInfo')},
        // {path: '/dinerList', component: () => import('@/pages/DinerList')},
        // {path: '/addDiner', component: () => import('@/pages/AddDiner')},
        // {path: '/walletInfo', component: () => import('@/pages/WalletInfo')},
        // {path: '/stopOrder', component: () => import('@/pages/StopOrder')},
        // {path: '/topUp', component: () => import('@/pages/TopUp')},
        // {path: '/parQrCode', component: () => import('@/pages/PayQrCode')}
    ]
})

// router.beforeEach((to, from, next) => {
//     if (to.path !== '/' && window.__wxjs_environment === 'miniprogram') {
//         // alert(`/pages${to.path}${to.path}`)
//         // eslint-disable-next-line no-undef
//         wx.miniProgram.navigateTo({url: '/pages' + to.path + to.path})
//     } else {
//         next()
//     }
// })

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    // eslint-disable-next-line no-undef
    document.title = to.meta.title || ''
});

export {router}
